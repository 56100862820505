import FiveGridLine_DoubleLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/DoubleLine/DoubleLine.skin';


const FiveGridLine_DoubleLine = {
  component: FiveGridLine_DoubleLineComponent
};


export const components = {
  ['FiveGridLine_DoubleLine']: FiveGridLine_DoubleLine
};

